<template>
  <transition name="slide-fade">
    <div
      class="form-alert"
      :class="`form-alert_${type}`"
    >
      <i
        class="close"
        @click="$emit('close')"
      >
        <img
          src="./../../assets/close-modal.svg"
          alt="close icon button"
        ></i>
      <h3 class="form-alert__header">
        {{ headerMessage }}
      </h3>
      <span class="form-alert__message"><slot /></span>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AppFormAlert',
  props: {
    type: {
      type: String,
      required: true,
      validator: (val) => ['success', 'warning', 'error'].includes(val)
    }
  },
  computed: {
    headerMessage () {
      return this.$t(`form_alert.${this.type}`)
    }
  }
}
</script>

<style lang="sass" scoped>
.form-alert
  @extend %alert-text
  width: 100%
  position: relative
  padding: 15px 15px 20px
  border-radius: 6px
  margin-top: 30px
  text-align: left
  &_success
    color:  rgb(139, 209, 24)
    background-color: rgba(139, 209, 24, 0.1)
    border: 1.5px solid rgba(139, 209, 24, 0.3)
  &_error
    background: rgba(237, 70, 47, 0.05)
    border: 1.5px solid rgba(237, 70, 47, 0.1)
    color: #ED462F
  &__header
    margin-bottom: 10px
  &__message
    color: black
.close
  position: absolute
  right: 15px
  top: 15px
  img
    width: 12px
    height: 12px

.slide-fade-enter-active
  transition: all .5s ease

.slide-fade-leave-active
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.slide-fade-enter, .slide-fade-leave-to
  transform: translateX(10px)
  opacity: 0

</style>

<template>
  <AppMainWindowContainer
    type="small"
    logo-type="big"
  >
    <template #header>
      <div class="login__header">
        <h1 class="header-text">
          {{ $t('login.header') }}
        </h1>
      </div>
    </template>

    <template #center>
      <form
        class="login__form"
        @submit.prevent="submitForm(true)"
      >
        <span style="color: transparent">{{ isFormValid }}</span>
        <AppInput
          v-model="form.login"
          :placeholder="$t('field_placeholder.email')"
          class="login-input"
          :validation-list="validationFields.login"
          show-validation-message
          :server-validation="serverValidationEmail"
          :onBlur="findEmail"
          :keyInput="'loginEmail'"
          @input="onEmailTyping"
        />

        <AppInput
          v-if="!isInValidUser"
          v-model="form.pass"
          input-type="password"
          :placeholder="$t('field_placeholder.password')"
        />

        <AppFormAlert
          v-show="error"
          type="error"
          @close="error = false"
        >
          {{ $t('login.errors.not_found') }}
        </AppFormAlert>

        <AppLoader
          v-if="isLoading"
          style="margin: 25px auto 0 auto"
        />
        <AppButton
          v-if="!isLoading && !isInValidUser"
          :disabled="!isFormValid"
          class="login__form__button"
        >
          {{ $t('login.form.button.submit') }}
        </AppButton>
      </form>
    </template>

    <template #bottom>
      <AppButton
        v-if="!isLoading && isInValidUser"
        class="login__form__button"
        @click.native="resetConfirmationToken"
      >
        Отправить письмо подтвердения
      </AppButton>
      <div class="login__footer">
        <AppLink
          v-if="!isLoading && !isInValidUser"
          tag="p"
          :to="{name: passwordRequestPath}"
          class="login__footer__password"
        >
          {{ $t('login.form.link.forget_password') }}
        </AppLink>
      </div>
      <div class="login__footer">
        <AppLink
          v-if="!isLoading"
          tag="p"
          :to="{name: 'PageOrganizationRegistration'}"
          class="login__footer__password"
        >
          {{ $t('login.form.link.registration') }}
        </AppLink>
      </div>
    </template>
  </AppMainWindowContainer>
</template>

<script>
import AppMainWindowContainer from '@/components/common/layout/AppMainWindowContainer'
import AppInput from '@/components/common/simple/AppInput'
import AppButton from '@/components/common/simple/AppButton'
import { validateEmail } from '@/utils/validation'
import AppLink from '@/components/common/simple/AppLink'
import AppFormAlert from '@/components/common/AppFormAlert'
import routeList from '@/router/labels'
import AppLoader from '@/components/AppLoader'
import { clearLocalStorage } from '@/mixins/local_storage'

export default {
  name: 'PageLogin',
  components: { AppLoader, AppFormAlert, AppLink, AppButton, AppInput, AppMainWindowContainer },
  data () {
    return {
      isLoading: false,
      emailFound: null,
      validEmail: null,
      emailFoundStatus: null,
      serverValidationInn: null,
      serverValidationEmail: null,
      passwordRequestPath: routeList.USER_RESET_PASSWORD_NAME,
      registrationRoute: routeList.USER_ORGANIZATION_REGISTRATION_ROUTE_NAME,
      form: {
        login: '',
        pass: '',
      },
      error: false,
      validationFields: {
        login: [
          {
            text: 'Электронная почта формата example@mail.com',
            valid: true,
            validator: (value) => validateEmail(value)
          }
        ],
      },
    }
  },
  computed: {
    isValidUser () {
      return this.emailFound > 0 && this.emailFoundStatus === true
    },
    isInValidUser () {
      return this.emailFound > 0 && this.emailFoundStatus === false
    },
    userExists () {
      const user = this.$store.state.users.info
      return !!user && !!user.id
    },
    isFormValid () {
      return this.validationFields.login.every((el) => el.valid) &&
        this.emailFound > 0 && this.emailFoundStatus === true &&
        (this.form.pass && this.form.pass.length >= 6)
    },
  },
  created () {
    if (this.userExists) {
      this.$router.push({ name: routeList.HOME_ROUTE_NAME })
    }
    // this.passwordRequestPath = routeList.USER_RESET_PASSWORD_PATH
    const login = this.$route.query.login
    if (login && validateEmail(login)) {
      this.form.login = login
      this.findEmail(login)
    }
    this.$store.commit('office/setAllOfficeItems', [], { root: true })
  },
  methods: {
    onEmailTyping (value, key) {
      if (key === 'loginEmail') {
        this.emailFound = null
        this.serverValidationEmail = null
        this.emailFoundStatus = null
      }
    },
    findEmail () {
      const rawLogin = this.form.login
      if (rawLogin && rawLogin.length) {
        this.form.login = rawLogin.trim()
      }
      const { login } = this.form
      if (validateEmail(login)) {
        this.$store.dispatch('users/fetchCheckEmail', { email: login.trim() })
          .then((response) => {
            this.emailFound = response.data.count
            this.emailFoundStatus = response.data.isActive
            if (this.emailFound === 0) {
              this.serverValidationEmail = [{ 'propertyPath': 'email', 'title': 'Данная почта не найдена' }]
            } else {
              if (this.emailFoundStatus === false) {
                this.serverValidationEmail = [{ 'propertyPath': 'email', 'title': 'Данная почта не подтверждена' }]
              } else {
                this.serverValidationEmail = null
              }
            }
          })
          .catch((error) => {
            this.serverValidationEmail = error.data.violationss
          })
      }
    },
    resetConfirmationToken () {
      const { login } = this.form

      if (validateEmail(login)) {
        this.isLoading = true
        this.$store.dispatch('users/resetConfirmationToken', { email: login.trim() })
          .then(() => {
            this.isLoading = false
            this.emailFound = null
            this.emailFoundStatus = null
            this.serverValidationEmail = null
          })
          .catch((error) => {
            console.log(error)
            this.isLoading = false
          })
      }
    },

    submitForm (submit = true) {
      clearLocalStorage()
      if (!submit) {
        this.resetConfirmationToken()
      } else {
        this.$store.dispatch('mercury/close')
        try {
          this.isLoading = true
          this.$store.dispatch('users/fetchAuthentication', { ...this.form, login: this.form.login.trim() })
            .then(() => this.$store.dispatch('users/fetchUserData'),
              () => {
                this.error = true
                this.isLoading = false
                setTimeout(() => {
                  this.error = false
                }, 5000)
              }).then(({ data }) => {
              if (data && data.roleList) {
                const redirectPath = this.$store.state.users.redirectPath || '/'
                this.$store.commit('users/clearRedirectPath')
                this.$router.push(redirectPath)
              } else {
                this.$router.push({ name: routeList.HOME_ROUTE_NAME })
                // TODO
              }
            })
        } catch (e) {
          this.isLoading = false
        }
      }
    },
    loadUserData () {

    }
  }
}
</script>

<style lang="sass" scoped>
  .login

    &__header
      text-align: center
      margin-bottom: 30px

    &__form
      display: flex
      flex-direction: column
      &__button
        width: 90%
        margin: 25px auto 0 auto

    &__footer
      display: flex
      flex-direction: column
      text-align: center
      align-items: center

      &__password
        margin-top: 25px

    &__splice-line
      width: 100%
      height: 1px
      margin: 30px 0
      background-color: $line-color_gray
</style>
